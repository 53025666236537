import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const FinancialPolicyPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const section1_img = data.section1_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/patient-information/financial-policy/"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Financial Policy endodontics pc, Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Financial Policy endodontics pc, Indianapolis IN"/>
        <meta name="description" content="Financial Policy and Payment Options for Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta name="og:description" content="Financial Policy and Payment Options for Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta property="og:url" content="https://meridianendoindy.com/patient-information/financial-policy/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/patient-information/financial-policy/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Financial Policy endodontics pc, Indianapolis IN"/>
        <meta name="twitter:description" content="Financial Policy and Payment Options for Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="financial-policy-hero-section-bg hero-bg"
        fluid={hero_background}
        alt="hero background image"
      >
        <div id="financial-policy-hero-section">
          <Container>
            <div className="col-lg-6 left-content pb-5">
              <h1 className="primary-text hero-heading">
                Financial Policy
              </h1>
              <h5>Patient Billing</h5>
              <p className="hero-text">
                For your convenience we accept Visa, MasterCard, Discover and American Express.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img" alt="lady wearing white shirt smiling"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
        <section id="financial-policy-section-1">
        <Container>
          <div className="col-lg-12">
            <h4>Patient Billing</h4>
            <p>
              Meridian Endodontics accepts most payment methods, including Visa, MasterCard, Discover, and American Express. Bills are due in full at the time of service unless arrangements were made before the appointment. If you believe there's been an error in billing, please contact us at <a href="tel:3178464980">317-846-4980</a>.
            </p>
            <p>
              Although we work with patients and insurance carriers, the patient is fully responsible for their balance, including any applicable fees. If you have a balance, we will send you a monthly statement until it's paid in full. Insurance reimbursement can take between four and six weeks. For discrepancies between the billing statement and insurance payments, please contact our office to review the matter.
            </p>
            <p>
              We appreciate your prompt action in resolving the balance due. Patients without insurance or with limited coverage may qualify for a monthly plan through Care Credit, a third-party financing service for medical and dental procedures. Any arrangements to pay must be approved prior to the appointment.
            </p>
          </div>
          <div className="col-lg-12 container-box">
            <div className="row">
              <div className="col-lg-9">
                <h5> Please remember you are fully responsible for all fees charged by this office regardless of your insurance coverage.</h5>
                <p>We will send you a monthly statement. Most insurance companies will respond within four to six weeks. Please call our office if your statement does not reflect your insurance payment within that time frame. Any remaining balance after your insurance has paid is your responsibility. Your prompt remittance is appreciated. We can make arrangements for a monthly payment plan through Care Credit, but this must be done prior to the actual procedure.</p>
              </div>
              <div className="col-lg-3 image-container">
                <Img fluid={section1_img} className="section1-img"/>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section id="financial-policy-section-2">
        <ContactForm />
      </section>
    </Layout>
  )
}

export default FinancialPolicyPage

export const query = graphql`
  query FinancialPolicyPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "financial-policy-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section1_img: file(name: { eq: "financial-policy-section1-img" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
